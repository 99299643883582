/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d42bb7ff-9544-4f9b-80a9-7cc943560b61",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FzroL40Gj",
    "aws_user_pools_web_client_id": "u6l555v4qtrovemngd93v6q0i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ac33n4sdvzcfxdn4muvvssvfem.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2lzt3rli6rhlbkwhfooaujcoei"
};


export default awsmobile;
