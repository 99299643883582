import React, { useEffect, useRef, useContext, useState } from 'react'
import canvasSketch from 'canvas-sketch'
import { lerp } from 'canvas-sketch-util/math'
import { createRandom } from 'canvas-sketch-util/random'
import { PaletteContext } from '../context/PaletteContext'

const Sketch3 = () => {
  const ref = useRef(null)
  const paletteContext = useContext(PaletteContext)
  const [mySeed, setMySeed] = useState(69)
  const [stayOnDesign, setStayOnDesign] = useState(false)

  const [pageStyle, setPageStyle] = useState({
    backgroundColor: paletteContext.paletteState.colors[0],
    height: '100vh',
  })

  const settings = {
    dimensions: [2048, 2048],
  }

  const updateSeedValue = () => {
    const newValue = Math.floor(Math.random() * 999) + 1
    setMySeed(newValue)
    return newValue
  }

  const sketch = () => {
    const random = createRandom()

    random.setSeed(stayOnDesign ? mySeed : updateSeedValue())
    const palette = paletteContext.getColors()

    const count = 85
    // const characters = ' →→→xyz↑↔↕↗↙⬅⬇'.split('')
    const characters = ' →↑↑↙↙↙'.split('')

    const createGrid = () => {
      const points = []
      for (let x = 0; x < count; x++) {
        for (let y = 0; y < count; y++) {
          const u = x / (count - 1)
          const v = y / (count - 1)
          const position = [u, v]
          const character = random.pick(characters)
          const r = /[AB]/i.test(character) ? 25 : 50
          const e = /[AB]/i.test(character) ? 10 : 20
          points.push({
            color: random.pick(palette),
            radius: Math.abs(r + e * random.gaussian()),
            position,
            character,
          })
        }
      }

      // points[Math.floor(Math.random() * points.length) + 1].character = 'neo'
      return points
    }

    let points = createGrid().filter(() => random.chance(0.4))

    // const myGrid = createGrid()
    //.filter(() => random.value() > 0.7)

    const margin = 400

    //Basic example from canvas-sketch repo
    return ({ context, width, height }) => {
      // const margin = width * 0.175

      context.fillStyle = palette[0]
      context.fillRect(0, 0, width, height)

      points.forEach((data) => {
        const { position, radius, color, character } = data
        const x = lerp(margin, width - margin, position[0])
        const y = lerp(margin, height - margin, position[1])

        // Draw the character
        context.fillStyle = color
        context.font = `${radius}px "SpaceGrotesk-Medium"`
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        context.fillText(character, x, y)
      })
    }
  }

  useEffect(() => {
    settings.canvas = ref.current
    setPageStyle({ backgroundColor: paletteContext.paletteState.colors[0] })
    canvasSketch(sketch, settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, paletteContext])

  const toggleStayOnDesign = () => {
    setStayOnDesign(!stayOnDesign)
    console.log(stayOnDesign)
  }

  return (
    <>
      <div style={pageStyle} className="flex justify-center">
        <canvas ref={ref} />
        <div class="mt-4">
          <input
            type="checkbox"
            class="form-checkbox h-5 w-5 text-red-600"
            checked={stayOnDesign}
            onClick={() => toggleStayOnDesign()}
          />
          <span class="ml-2 text-gray-700">lock pattern</span>
        </div>
      </div>
    </>
  )
}

export default Sketch3
