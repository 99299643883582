import React, { useState, useEffect, useContext } from 'react'
// import GradientBar from './components/common/GradientBar';
import Header from './components/Header'
import { PaletteContext } from './context/PaletteContext'

const AppShell = ({ children }) => {
  const paletteContext = useContext(PaletteContext)

  const changePalette = () => {
    paletteContext.changePalette()
  }

  const shuffleColors = () => {
    paletteContext.shufflePalette()
  }

  const [pageStyle, setPageStyle] = useState({
    backgroundColor: paletteContext.paletteState.colors[1],
  })

  const [headerStyle, setHeaderStyle] = useState({
    // color: 'white',
    backgroundColor: paletteContext.paletteState.colors[4],
    // padding: '10px',
    fontFamily: 'Arial',
  })
  const [footerStyle, setFooterStyle] = useState({
    // color: 'white',
    backgroundColor: paletteContext.paletteState.colors[4],
    // fontFamily: 'Arial',
  })

  useEffect(() => {
    setPageStyle({ backgroundColor: paletteContext.paletteState.colors[0] })
    setFooterStyle({ backgroundColor: paletteContext.paletteState.colors[4] })
    setHeaderStyle({ backgroundColor: paletteContext.paletteState.colors[4] })
  }, [paletteContext])

  useEffect(() => {
    // paletteContext.changePalette()
  }, [])

  return (
    <>
      <Header />
      <div style={pageStyle} class="shadow-lg mx-auto mt-24 md:mt-16 h-screen">
        {children}
      </div>
    </>
  )
}

export default AppShell
