import React, { useEffect, useRef, useContext, useState } from 'react'
import canvasSketch from 'canvas-sketch'
import { lerp } from 'canvas-sketch-util/math'
import { createRandom } from 'canvas-sketch-util/random'
import { PaletteContext } from './../context/PaletteContext'

const Sketch = () => {
  const paletteContext = useContext(PaletteContext)

  const [pageStyle, setPageStyle] = useState({
    backgroundColor: paletteContext.paletteState.colors[0],
    height: '100vh',
  })
  const ref = useRef(null)

  const settings = {
    dimensions: [2048, 2048],
  }

  const [mySeed, setMySeed] = useState(69)
  const [stayOnDesign, setStayOnDesign] = useState(false)

  const sketch = () => {
    const random = createRandom()

    const updateSeedValue = () => {
      const newValue = Math.floor(Math.random() * 999) + 1
      setMySeed(newValue)
      return newValue
    }

    random.setSeed(stayOnDesign ? mySeed : updateSeedValue())
    const palette = paletteContext.getColors()

    const createGrid = () => {
      const points = []
      const count = 31

      for (let x = 0; x < count; x++) {
        for (let y = 0; y < count; y++) {
          const u = count <= 1 ? 0.5 : x / (count - 1)
          const v = count <= 1 ? 0.5 : y / (count - 1)

          points.push({
            radius: Math.abs(0.01 + random.gaussian() * 0.01),
            position: [u, v],
          })
        }
      }
      // console.log(points)
      return points
    }

    const myGrid = createGrid()
    //.filter(() => random.value() > 0.7)

    const margin = 400

    //Basic example from canvas-sketch repo
    return ({ context, width, height }) => {
      context.fillStyle = palette[0]
      context.fillRect(0, 0, width, height)

      myGrid.forEach((data) => {
        const { position, radius } = data
        const [u, v] = position

        const x = lerp(margin, width - margin, u)
        const y = lerp(margin, height - margin, v)
        // console.log(`x, y = ${x},${y}`)

        context.beginPath()
        context.arc(x, y, radius * width, 0, Math.PI * 2, false)

        context.fillStyle = random.pick(palette)
        context.lineWidth = 20
        context.fill()
      })
    }
  }

  useEffect(() => {
    settings.canvas = ref.current
    setPageStyle({ backgroundColor: paletteContext.paletteState.colors[0] })
    canvasSketch(sketch, settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, paletteContext])

  const toggleStayOnDesign = () => {
    setStayOnDesign(!stayOnDesign)
    console.log(stayOnDesign)
  }

  return (
    <>
      <div style={pageStyle} className="flex justify-center">
        <canvas ref={ref} />
        <div class="mt-4">
          <input
            type="checkbox"
            class="form-checkbox h-5 w-5 text-red-600"
            checked={stayOnDesign}
            onClick={() => toggleStayOnDesign()}
          />
          <span class="ml-2 text-gray-700">lock pattern</span>
        </div>
      </div>
    </>
  )
}

export default Sketch
