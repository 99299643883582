import React, { useState, useContext, useEffect } from 'react'
import { PaletteContext } from './../context/PaletteContext'

const Order = () => {
  const paletteContext = useContext(PaletteContext)

  const [titleColor, setTitleColor] = useState({
    backgroundColor: paletteContext.paletteState.colors[3],
  })

  const [detailsColor_1, setDetailsColor_1] = useState({
    backgroundColor: paletteContext.paletteState.colors[2],
  })

  const [detailsColor_2, setDetailsColor_2] = useState({
    backgroundColor: paletteContext.paletteState.colors[2],
  })

  const [totalColor, setTotalColor] = useState({
    backgroundColor: paletteContext.paletteState.colors[1],
  })

  useEffect(() => {
    setTitleColor({ backgroundColor: paletteContext.paletteState.colors[3] })
    setDetailsColor_1({
      backgroundColor: paletteContext.paletteState.colors[2],
    })
    setDetailsColor_2({
      backgroundColor: paletteContext.paletteState.colors[2],
    })
    setTotalColor({ backgroundColor: paletteContext.paletteState.colors[1] })
  }, [paletteContext])

  return (
    <>
      <div class="flex flex-col h-full justify-evenly ">
        <div
          style={titleColor}
          class="self-center flex-initial h-20 w-1/2 text-center mt-8"
        >
          Title
        </div>

        <div className="self-center flex flex-1 h-64 w-3/4 space-x-2 m-8">
          <div style={detailsColor_1} class="flex-initial w-1/3 text-center">
            Details 1
          </div>
          <div style={detailsColor_2} class="flex-initial w-2/3  text-center">
            Details 2
          </div>
        </div>

        <div
          style={totalColor}
          class="self-end flex-initial w-1/3 h-24 text-center mr-20"
        >
          Total
        </div>
      </div>
    </>
  )
}

export default Order
