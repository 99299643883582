import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { PaletteContext } from './../context/PaletteContext'

const Header = () => {
  const paletteContext = useContext(PaletteContext)

  const changePalette = () => {
    paletteContext.changePalette()
  }

  const shuffleColors = () => {
    paletteContext.shufflePalette()
  }

  return (
    <>
      <nav class="bg-gray-800 p-2 mt-0 fixed w-full z-10 top-0">



        <div class="container mx-auto flex flex-wrap items-center">
          <div class="flex w-full md:w-1/2 justify-center md:justify-start text-gray-600 font-extrabold">
            <span class="text-2xl pl-2">
              mikebasdeo
            </span>
          </div>
          <div class="flex w-full pt-2 content-center justify-between md:w-1/2 md:justify-end">
            <ul class="list-reset flex justify-between flex-1 md:flex-none items-center">
              <li class="mr-3">
                <Link to="/order">
                  <button className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4">
                    Form
                  </button>
                </Link>
              </li>
              <li class="mr-3">
                <Link to="/sketch">
                  <button className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4">
                    Sketch
                  </button>
                </Link>
              </li>

              <li class="mr-3">
                <Link to="/sketch2">
                  <button className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4">
                    Sketch2
                  </button>
                </Link>
              </li>

              <li class="mr-3">
                <Link to="/sketch3">
                  <button className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4 mr-4">
                    Sketch3
                  </button>
                </Link>
              </li>

              <li class="mr-3">
                <button
                  onClick={() => shuffleColors()}
                  className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4 ml-4"
                >
                  Swap Colors
                </button>
              </li>
              <li class="mr-3">
                <button
                  onClick={() => changePalette()}
                  className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4"
                >
                  Change Palette
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
