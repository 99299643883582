import React, { createContext, useState } from 'react'
// import { palette, shuffle } from './utils/globals'
import palettes from 'nice-color-palettes/1000'

const PaletteContext = createContext()
const { Provider } = PaletteContext

const PaletteProvider = ({ children }) => {
  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  const [paletteState, setPaletteState] = useState({
    colors: palettes[Math.floor(Math.random() * 999) + 1],
  })

  const shufflePalette = () => {
    setPaletteState({ colors: shuffle(paletteState.colors) })
  }

  const changePalette = () => {
    const newChoice = Math.floor(Math.random() * 999) + 1
    setPaletteState({ colors: palettes[newChoice] })
  }

  const getColors = () => {
    return paletteState.colors
  }

  return (
    <Provider
      value={{
        paletteState,
        getColors,
        shufflePalette,
        changePalette
      }}
    >
      {children}
    </Provider>
  )
}

export { PaletteContext, PaletteProvider }
