/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import logo from './logo.svg'
import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from 'react-router-dom'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import { createTodo } from './graphql/mutations'
// import Home from './pages/Home'

import { PaletteContext, PaletteProvider } from './context/PaletteContext'

import Graph from './pages/Graph'
import AppShell from './AppShell'
import Work from './pages/Work'
import Sketch from './pages/Sketch'
import Order from './pages/Order'
import Sketch2 from './pages/Sketch2'
import Sketch3 from './pages/Sketch3'

import Amplify, { API, graphqlOperation } from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

const App = () => {
  // const [theme, setTheme] = useState(0)

  // const activateLasers = async () => {
  //   // console.log('hello from buttonclick')
  //   const todo = {
  //     name: 'My first todo',
  //     description: 'Hello world!',
  //     priority: 'beerwalk',
  //   }
  //   const result = await API.graphql(
  //     graphqlOperation(createTodo, { input: todo })
  //   )
  //   if (result) {
  //     console.log(result)
  //   }
  // }
  return (
    <Router>
      <PaletteProvider>
        <div>
          <Switch>
            <Route path="/order">
              <AppShell>
                <Order />
              </AppShell>
            </Route>
            <Route path="/sketch">
              <AppShell>
                <Sketch />
              </AppShell>
            </Route>
            <Route path="/sketch2">
              <AppShell>
                <Sketch2 />
              </AppShell>
            </Route>
            <Route path="/sketch3">
              <AppShell>
                <Sketch3 />
              </AppShell>
            </Route>
            <Route path="/">
              <AppShell>
                <Sketch />
              </AppShell>
            </Route>
          </Switch>
        </div>
      </PaletteProvider>
    </Router>
  )
}

export default App
